import { object, string } from 'yup';

const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\W)(?!.*\s).{12,24}$/;
const USERNAME_REGEX = /^[A-Za-z][A-Za-z0-9_]{5,50}$/;

export const SignupSchema = object({
    email: string().lowercase("Email address should not have uppercase letters").max(200, "Email address cannot be more than 200 characters")
    .required('Required')
    .email('Email address is not valid'),
    username: string().required("Required").matches(USERNAME_REGEX, {
        excludeEmptyString: true,
        message: "Username does not meet minimum username requirement"
    }),
    phone: string().required('Phone number is required').test({
        name: 'ValidatePhoneNumber',
        skipAbsent: false,
        test(value, ctx) {
            const phoneNumber = value.substring(1, value?.length);
            if (!value) {
                return ctx.createError({ message: 'Required'})
            }
            if (phoneNumber?.length < 8 || phoneNumber?.length > 12) {
                return ctx.createError({ message: 'Phone Number is not valid'})
            }
            if (value[0] !== '+') {
                return ctx.createError({ message: 'Phone Number should have country code as prefix with +'})
            }
            let validNumber = true;
            for (let i = 0; i < phoneNumber.length; i++){
                if (phoneNumber[i] >= '0' && phoneNumber[i] <= '9') {
                    continue;
                }
                validNumber = false;
                break;
            }
            if (!validNumber) {
                return ctx.createError({ message: 'Phone number is not valid' });
            }
            return validNumber;
        }
    }),
    password: string().required('Required').matches(PASSWORD_REGEX, {
        excludeEmptyString: true,
        message: 'Password does not meet minimum password requirement'
    })
});

export const LoginSchema = object({
    username: string().required("Required"),
    password: string().required("Required")
});

export const ForgotPasswordSchema = object({
    username: string().trim().required("Required").matches(USERNAME_REGEX, {
        excludeEmptyString: true,
        message: "Username is not valid",
    })
});

export const ForgotUsernameSchema = object({
    email: string().trim().required('Required').email('Email address is not valid')
});

export const ResetPasswordSchema = object({
    password: string().trim().required("Required").matches(PASSWORD_REGEX, {
        excludeEmptyString: true,
        message: "Password does not meet minimum password requirement"
    }),
    confirmPassword: string().trim().required("Required").matches(PASSWORD_REGEX, {
        excludeEmptyString: true,
        message: "Password does not meet minimum password requirement"
    })
});

export const BillingInfoSchema = object({
    first_name: string().trim().required("Required").max(50).matches(/^[a-zA-Z]+$/, {
        excludeEmptyString: true,
        message: "Should have only letters"
    }),
    last_name: string().trim().required("Required").max(50).matches(/^[a-zA-Z]+$/, {
        excludeEmptyString: true,
        message: "Should have only letters"
    }),
    company_name: string().trim().optional().nullable().max(150).matches(/^[a-zA-Z\.-\s]+$/, {
        excludeEmptyString: true,
        message: "Should have only letters and space"
    }),
    address_line_1: string().trim().required("Required").min(5, 'Address should have minimum of 5 characters').max(150).matches(/^[a-zA-Z0-9,\-\s\/]+$/, {
        excludeEmptyString: true,
        message: "Can have letters, numbers, comma, space, hyphen and slash"
    }),
    address_line_2: string().trim().optional().max(150).matches(/^[a-zA-Z0-9,\-\s\/]+$/, {
        excludeEmptyString: true,
        message: "Can have letters, numbers, comma, space, hyphen and slash"
    }),
    city: string().trim().required("Required").max(150, "City should have maximum of 150 characters").matches(
        /^[a-zA-Z\s]+$/, {
            excludeEmptyString: true,
            message: "Should have only letters"
        }
    ),
    country: string().trim().required("Required").max(150, "Country should have maximum of 150 characters"),
    state: string().trim().required("Required").max(150, "State should have maximum of 150 characters").matches(
        /^[a-zA-Z\s]+$/, {
            excludeEmptyString: true,
            message: "Should have only letters and space"
        }
    ),
    postal_code: string().trim().required("Required").max(20, "Zip Code should have maximum of 20 characters").matches(
        /^[a-zA-Z0-9]+$/, {
            excludeEmptyString: true,
            message: "Should have only letters and digits"
        }
    ),
});

export default {};
